<template>
  <v-skeleton-loader
    type="image"
    class="escalation"
    v-if="!account"
  />
  <v-card
    v-else
    class="escalation"
  >
    <v-card-title
      v-text="$t('t.Escalation')"
      class="headline"
    />
    <v-card-text v-if="escalation">
      <div
        class="subtitle-1"
        v-text="escalation.name"
      />

      <v-stepper v-model="current">
        <v-stepper-header>
          <v-divider v-if="prevLevel" />

          <v-stepper-step
            :step="prevLevel.sequence"
            v-if="prevLevel"
          >{{prevLevel.name}}</v-stepper-step>

          <v-divider v-if="prevLevel" />

          <v-stepper-step :step="currentLevel.sequence">
            <div v-text="currentLevel.name" />
            <calendar-date :date="escalation.startDate" />
          </v-stepper-step>

          <v-divider v-if="nextLevel" />

          <v-stepper-step
            :step="nextLevel.sequence"
            v-if="nextLevel"
          >{{nextLevel.name}}</v-stepper-step>

          <v-divider v-if="nextLevel" />
        </v-stepper-header>
      </v-stepper>
    </v-card-text>
    <v-card-text v-else>
      {{$t('t.OutOfEscalation')}}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date')
  },
  computed: {
    current () {
      return this.escalation?.currentLevel?.sequence
    },
    currentLevel () {
      return this.escalation?.currentLevel
    },
    nextLevel () {
      return this.escalation?.nextLevel
    },
    prevLevel () {
      return this.escalation?.prevLevel
    },
    escalation () {
      return this.account?.escalation
    }
  },
  props: {
    account: Object
  }
}
</script>
